<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
        max-width="680"
        min-height="100vh"
    >
        <v-sheet
            rounded
            outlined
            class="pa-3 d-flex align-center justify-sm-start justify-center flex-column flex-sm-row mb-6"
        >
            <div>
                <member-avatar-form
                    v-if="$auth.isEmployee()"
                    :member-type="memberType"
                    :member="member"
                    :rounded="isRounded"
                    :size="avatarSize"
                />
                <member-avatar
                    v-else
                    :item="member"
                    :rounded="isRounded"
                    :size="avatarSize"
                />
            </div>
            <div class="pl-sm-3 text-center text-sm-start mt-3 mt-sm-0">
                <div class="overline">{{ memberType | memberTypeLabel(member.gender) }}</div>
                <h4>{{ member | fullName }}</h4>
                <small class="caption">{{ member.hint }}</small>
            </div>
        </v-sheet>
        <h4 class="text-center mb-4">{{ $trans('Personal data') }}</h4>
        <auth-personal-data/>
        <v-card
            outlined
            class="mt-4"
        >
            <settings-list-item
                :title="$trans('Settings')"
                icon="CONFIG_OUTLINE"
                color="primary"
                :to="{name: 'auth.settings'}"
                class="py-1"
            />
        </v-card>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import AuthPersonalData from '@apps/auth/components/AuthPersonalData'
import MemberAvatar from '@/components/Member/MemberAvatar'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import MemberAvatarForm from '@apps/auth/components/MemberAvatarForm'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import hasFiltersOfMemberMixin from '@apps/auth/mixins/hasFiltersOfMemberMixin'
import SettingsListItem from '@apps/settings/components/SetingsList/SettingsListItem'

export default {
    name: 'AuthProfile',
    mixins: [
        hasMemberFullNameFilterMixin,
        mobileBreakpointChecker,
        hasFiltersOfMemberMixin
    ],
    components: {
        SettingsListItem,
        MemberAvatarForm,
        MemberAvatar,
        AuthPersonalData,
        ContentWrapper
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Profile')
                }
            ]
        },
        member() {
            return this.$auth.member
        },
        memberType() {
            return this.$auth.memberType
        },
        isRounded() {
            return !this.isMobile
        },
        avatarSize() {
            return this.isMobile
                ? 86
                : 140
        }
    }
}
</script>

<style lang=scss>

</style>
