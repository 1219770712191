import { pluralizationResolver } from '@/plugins/Trans'

export const genders = {
    MALE: 'male',
    FEMALE: 'female'
}

export const memberTypeLabel = (type, gender) => {
    const number = gender === genders.FEMALE ? 1 : 0
    const key = type + '_gender'

    const result = pluralizationResolver(key, number)

    return result === key
        ? type
        : result
}
