<template>
    <v-card outlined>
        <v-card-text>
            <template
                v-for="(item, index) in personalData"
            >
                <v-divider
                    v-if="index > 0"
                    :key="`divider-${index}`"
                />
                <div
                    :key="index"
                    class="d-sm-flex justify-space-between py-3"
                >
                    <div
                        class="text--secondary caption text-uppercase"
                    >
                        {{ item.label }}
                    </div>
                    <div class="font-weight-bold caption">
                        {{ item.value }}
                    </div>
                </div>

            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
    name: 'AuthPersonalData',
    computed: {
        user() {
            return this.$auth.user
        },
        member() {
            return this.$auth.member
        },
        memberTypeLabel() {
            return this.$trans(_.upperFirst(this.$auth.memberType))
        },
        personalData() {
            moment.updateLocale(this.$store.getters.locale, {})
            const member = this.member

            return [
                {
                    label: this.$trans('Birthday'),
                    value: moment(member.birthday).format('DD MMMM, YYYY')
                },
                {
                    label: this.$trans('Phone'),
                    value: member.phone
                },
                {
                    label: this.$trans('Email'),
                    value: member.email
                }
            ]
        }
    }
}
</script>

<style lang=scss>

</style>
